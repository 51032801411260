<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.name"
        :rules="$options.rules.name"
        label="Nom"
        type="text"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.category"
        label="Catégorie d'opération"
        :item-list="operationCategoryList"
        item-text="name"
        clearable
        @input="change"
      />

      <v-textarea
        v-model="internalValue.description"
        label="Description"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.control"
        tooltip="L'opération peut être réalisée à tout moment"
        inset
        label="Operation parallèle"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.multiParts"
        tooltip="L'opération permet de saisir plusieurs pièces à la fois"
        inset
        label="Autoriser la saisie de plusieurs pièces à la fois"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.alterationAllowed"
        tooltip="Si une pièce est non conforme, l'opérateur peut l'envoyer en retouche"
        inset
        label="Autoriser les retouches"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.alteration"
        tooltip="Opération vers laquelle les pièces à retoucher sont envoyées"
        inset
        label="Opération de retouche"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.isDelay"
        tooltip="Permet de spécifier un délais avant que la pièce soit réalisable"
        inset
        label="Activer un délais pour l'opération"
        @input="change"
      />

      <v-text-field
        v-if="internalValue.isDelay"
        v-model="internalValue.delay"
        type="number"
        label="Nombre d'heures de délais"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.itemPerPageAllowedList"
        label="Nbr d'éléments par page"
        :item-list="itemPerPageAllowedListPossibility"
        multiple
        chips
        deletable-chips
        clearable
        @input="change"
      />

      <ag-switch
        v-model="internalValue.allItemPerPageAllowed"
        tooltip="Autoriser d'afficher toutes les pièces sur une page"
        inset
        label="Valeur tous autorisé"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.canChainPartEntry"
        tooltip="Autoriser l'utilisateur à enchaîner les saisies des pièces"
        inset
        label="Enchainer la saisie des pièces"
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgSwitch from 'src/components/common/AgSwitch'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'OperationTypeForm',
  components: { AgSwitch, AgAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    operationCategoryList: [],
    itemPerPageAllowedListPossibility: [],
  }),
  computed: {
    isValidOperationType () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidOperationType)
      },
    },
    isValidOperationType: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidOperationType)
      },
    },
    'internalValue.isDelay': {
      immediate: false,
      handler () {
        this.internalValue.delay = 0
      },
    },
  },
  async mounted () {
    this.operationCategoryList = await Repositories.operationCategory.getList()
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < 100; index++) {
      this.itemPerPageAllowedListPossibility.push(index + 1)
    }
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidOperationType)
    },
  },
  rules: {
    name: [
      FormRules.required('requis'),
    ],
    delay: [
      FormRules.required('requis'),
      FormRules.greater('Veuilez saisir une valeur positive', { number: 0 }),
      FormRules.integer('Veuilez saisir une valeur entière'),
    ],
  },
}
</script>
