<template>
  <ag-modal-container
    title="Ajouter un type d'opération"
    width="800px"
    @close="back"
  >
    <operationType-form
      v-model="value"
      @validate="isValid = $event"
    />

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!isValid"
        color="accent"
        class="ml-5"
        @click="create"
        v-text="'Ajouter'"
      />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import OperationTypeForm from '../components/OperationTypeForm'

export default {
  name: 'OperationTypeCreateContainer',
  components: {
    AgModalContainer,
    OperationTypeForm,
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: {
      name: '',
      description: '',
      control: false,
      multiParts: false,
      alterationAllowed: false,
      alteration: false,
      isDelay: false,
      delay: 0,
      category: null,
      itemPerPageAllowedList: [],
      allItemPerPageAllowed: false,
      canChainPartEntry: false,
    },
  }),
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
    async create () {
      this.loading = true
      try {
        await Repositories.operationType.post(this.value)
        this.showSuccessNotification(`Le type d'opération ${this.value.name} a bien été créé`)
        this.back()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>
